import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { stripTags } from '@lib/striptags';

export const WavyHeroTitle = props => {
    const { children, className } = props;
    const words = children.split(' ');
    const shouldReduceMotion = useReducedMotion();

    const variants = !shouldReduceMotion
        ? {
              hidden: { y: '100%' },
              visible(delay) {
                  return {
                      y: 0,
                      transition: {
                          delay: delay * 0.1,
                          type: 'spring',
                          duration: 0.2,
                          bounce: 0.5,
                          damping: 10,
                          mass: 1,
                          stiffness: 100,
                      },
                  };
              },
          }
        : {};

    return (
        <h1 className={className}>
            <span className="screen-reader-text">{stripTags(children)}</span>

            <div role="presentation">
                {words.map((word, i) => {
                    const wrapInSpan = word.includes('span');
                    const doBreakLine = word.includes('br');

                    return (
                        <React.Fragment key={i}>
                            {doBreakLine && <br />}

                            <div style={{ display: 'inline-block', overflow: 'hidden' }}>
                                <motion.div
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        top: '-0.9rem',
                                    }}
                                    variants={variants}
                                    initial="hidden"
                                    animate="visible"
                                    custom={i}
                                >
                                    {wrapInSpan ? <span>{stripTags(word)}</span> : stripTags(word)}
                                </motion.div>
                            </div>

                            {i !== words.length - 1 ? ' ' : ''}
                        </React.Fragment>
                    );
                })}
            </div>
        </h1>
    );
};
