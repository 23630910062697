import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';

export const FadeInWhenVisible = props => {
    const {
        children,
        direction = 'bottom',
        duration = 0.5,
        delay = 0,
        className = '',
        threshold = 1,
        amount = 25,
    } = props;
    const controls = useAnimation();
    const shouldReduceMotion = useReducedMotion();
    const { ref, inView } = useInView({ threshold, triggerOnce: true });

    const variants = {
        hidden: { opacity: shouldReduceMotion ? 1 : 0 },
        visible: { opacity: 1, transition: { duration, delay } },
    };

    const key = direction === 'bottom' || direction === 'top' ? 'y' : 'x';
    const startPosition =
        direction === 'center' || shouldReduceMotion
            ? '0'
            : direction === 'bottom' || direction === 'right'
            ? amount
            : -amount;

    variants.hidden[key] = startPosition;
    variants.visible[key] = 0;

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <div ref={ref} {...(className ? { className } : {})}>
            <motion.div variants={variants} animate={controls} initial="hidden">
                {children}
            </motion.div>
        </div>
    );
};
